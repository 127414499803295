import { useEffect, useState } from "react";
import LoadingSpinner from "components/LoadingSpinner";
import ComboBox from "components/FormsElement/ComboBox";
import SisenseListBox from "components/Sisense/SisenseListBox";
import SisenseComponent from "components/Sisense/Sisense";
import { fetchAnalyticReportList } from "api/networkReportSvc";
import { useAppSelector } from "hooks/reduxHook";
import { AnalyticReport } from "api/dataObjects/AnalyticReport";
import useAppLanguage from "hooks/useAppLanguage";

interface Option {
  id: string;
  name: string;
}

const initalComboBoxValue: Option = { id: "all", name: "All Dashboards" };
const AnalyticsHome = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dashboard, setDashboard] = useState<Option[]>([] as Option[]);
  const [analyticReports, setAnalyticReports] = useState<AnalyticReport[]>([]);
  const [choosedValue, setChoosedValue] = useState(initalComboBoxValue);
  const [seletedReport, setSeletedReport] = useState<AnalyticReport>({});

  const networkIdReduxData: any = useAppSelector(
    (state) => state?.userActors?.actors[0]?.network_id
  );

  const { translate } = useAppLanguage();

  useEffect(() => {
    try {
      (async () => {
        if (networkIdReduxData) {
          const analyticReport = await fetchAnalyticReportList(
            networkIdReduxData
          );
          const parseData = JSON.parse(JSON.stringify(analyticReport));
          const optionsList = parseData.map((val: AnalyticReport) => {
            return { id: val.sisense_dashboard_id, name: val.report_name };
          });
          setDashboard(optionsList);
          setAnalyticReports(parseData);
          setIsLoading(false);
        }
      })();
    } catch (e) {
      setIsLoading(false);
      console.log("Error:", e);
    }
  }, [networkIdReduxData]);

  const handleChange = (e: any) => {
    setChoosedValue(e);
  };

  useEffect(() => {
    if (analyticReports) {
      const cd = analyticReports.filter((val: any) => {
        return val.sisense_dashboard_id === choosedValue.id;
      });
      setSeletedReport(cd[0]);
    }
  }, [analyticReports, choosedValue]);
  return (
    <>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <>
          <div className=" text-black text-3xl font-bold font-['Moderat'] leading-10 pb-4">
            {translate("Analytics")}
          </div>
          <div className="w-48 py-6 ">
            <ComboBox
              options={[initalComboBoxValue, ...dashboard]}
              onSelect={handleChange}
              value={choosedValue}
            />
          </div>
          {choosedValue?.id === "all" ? (
            <div className="flex flex-wrap  gap-2 ">
              {analyticReports.map((val, key) => {
                return (
                  <div className="" key={key}>
                    <SisenseListBox report={val} callback={handleChange} />
                  </div>
                );
              })}
            </div>
          ) : (
            <SisenseComponent
              key={Math.random()}
              url={seletedReport?.sisense_url}
              wat={seletedReport?.sisense_web_access_token}
              dashboard={seletedReport?.sisense_dashboard_id}
            />
          )}
          {analyticReports.length === 0 && !isLoading && (
            <div>{translate("Analytics Reports are not available.")}</div>
          )}
        </>
      )}
    </>
  );
};

export default AnalyticsHome;
