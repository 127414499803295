import HelmetTitle from "components/HelmetTitle";
import { ReactComponent as MainLogoIcon } from "assets/images/footer_logo.svg";
interface PageBaseProps {
  children: React.ReactNode;
  pageTitle: string;
  appName?: string; // Optional page title is to add additional title. e.g. pageTitle - Title
}

const PageBaseWithoutPanel: React.FC<PageBaseProps> = ({
  children,
  pageTitle = "Coordinated Enrollment",
  appName = "Wonderschool", // TODO: Change to Network once the domain config is in place.
}) => {
  return (
    <>
      <HelmetTitle pageTitle={pageTitle} appName={appName} />
      <div className="flex ">
        <div className="flex-grow  h-screen overflow-auto">
          <div className="p-4 flex justify-center items-center h-screen">
            {children}
          </div>

          <div className="text-black text-base font-normal leading-10 bottom-0 right-0 absolute flex items-center">
            <div>Built with ❤️ by </div>
            <div>
              <MainLogoIcon />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageBaseWithoutPanel;
