import { Expose } from "class-transformer";

export class Network {
  constructor(obj: object = {}) {
    Object.assign(this, obj);
  }

  @Expose({ name: "network_id" })
  public networkId?: string;

  @Expose({ name: "name" })
  public name?: string;

  @Expose({ name: "public_domain_id" })
  public public_domain_id?: string;

  @Expose({ name: "primary_phone" })
  public primary_phone?: string;

  @Expose({ name: "primary_email_address" })
  public primary_email_address?: string;
}
