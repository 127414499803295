import { plainToInstance } from "class-transformer";
import { UserMgmtRecord } from "./dataObjects/UserManagment";
import http from "./httpService/http";
import { paths } from "./paths";
import { NetworkUser } from "./dataObjects/NetworkUser";

const userListPath = paths.network_users.userList;
const userNetworkPath = paths.user.userGet;
const getLoginUserApiPath = paths.user.getLoginUser;

export const fetchUserManagementList = async (
  network_id: string
): Promise<UserMgmtRecord> => {
  const response = await http.get<object, any>(userListPath(network_id));
  return plainToInstance(UserMgmtRecord, response.data) as UserMgmtRecord;
};

/**
 * This function updates a User Management Record for a User
 * @param network_id The network id
 * @param body The UserMgmtRecord object with the updated values
 * @returns The UserMgmtRecord object returned from the API
 */
export const updateUserManagementRecord = async (
  network_id: string,
  body: UserMgmtRecord
): Promise<UserMgmtRecord> => {
  const response = await http.put<object, any>(userListPath(network_id), body);
  return plainToInstance(UserMgmtRecord, response.data) as UserMgmtRecord;
};

export const fetchUserDetails = async (
  user_id: string,
  network_id: string
): Promise<NetworkUser> => {
  const response = await http.get<object, any>(
    userNetworkPath(user_id, network_id)
  );
  return plainToInstance(NetworkUser, response.data) as NetworkUser;
};

export const fetchLoginUserDetails = async (): Promise<NetworkUser> => {
  const response = await http.get<object, any>(getLoginUserApiPath());
  return plainToInstance(NetworkUser, response.data) as NetworkUser;
};
