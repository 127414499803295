export class AnalyticReport {
  constructor(obj: object = {}) {
    Object.assign(this, obj);
  }

  public analytic_report_id?: string;

  public active?: boolean;

  public network_id?: string;

  public display_order?: number;

  public report_name?: string;

  public preview_image_url?: string;

  public sisense_web_access_token?: string;

  public sisense_url?: string;

  public sisense_dashboard_id?: string;
}
