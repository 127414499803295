//
// This file has all the paths to the endpoints
// All endpoints should have the same path for all methods
//  ^Create can pass empty string for id, cause id is omitted for post
//

export const paths = {
  index: "/",
  userManagment: "/user-management",
  helpCenter: "/help-center",
  secretPage: "secret-page",
  verify: "/verify/:invite_id/:secret",
  noAccess: "/no-access",
};

export const redirects = {
  anonymous: "/anonymous",
  signIn: "/sign-in",
  signOut: "/sign-out",
};
