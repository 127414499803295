// Initializes Firebase
import { Provider, ErrorBoundary } from "@rollbar/react";
import { getRollbarConfig } from "./providers/rollbar/config";
import ApplicationProvider from "providers/ApplicationProvider";
import { Provider as ReduxProvider } from "react-redux";
import { persistor, store } from "reduxApp/store";
import { PersistGate } from "redux-persist/integration/react";
import AppRoutes from "routes";
import "./api/firebase";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "index.css";
import "./default-font.css"; // Import the custom CSS here
import LoadingSpinner from "components/LoadingSpinner";

export const App = () => {
  return (
    <ReduxProvider store={store}>
      <Provider config={getRollbarConfig()}>
        <PersistGate loading={<LoadingSpinner />} persistor={persistor}>
          <ErrorBoundary>
            <ApplicationProvider>
              <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
              />
              <AppRoutes />
            </ApplicationProvider>
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </ReduxProvider>
  );
};
