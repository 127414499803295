import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchDomainConfig } from "api/domainConfigSvc";
import { IDomainConfig, INetwork, INetworkActors } from "../types";

type IinitialState = {
  config: IDomainConfig;
  loading: boolean;
  networkActorsList: any[];
};

const initialState: IinitialState = {
  config: {
    domainId: "",
    networkId: "",
    host: "",
    networkLogo: "",
    network: {} as INetwork,
    networkActors: [] as INetworkActors[],
  },
  loading: false,
  networkActorsList: [],
};

// function actually call get for domain config data
export const getDomainConfig = createAsyncThunk(
  "get/fetchDomainConfiguration",
  async (dummy, { rejectWithValue }) => {
    try {
      const response = await fetchDomainConfig();
      return JSON.stringify(response);
    } catch (err: any) {
      return rejectWithValue({ error: err.message });
    }
  }
);

const domainConfigSlice = createSlice({
  name: "domain",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(getDomainConfig.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getDomainConfig.fulfilled, (state, action) => {
      const domainConfigData = JSON.parse(action.payload);
      state.config = domainConfigData;
      state.loading = false;
      state.networkActorsList = domainConfigData.networkActors.map(
        (actor: INetworkActors) => {
          // Randomly select the providerStatus
          const randomStatusIndex = Math.floor(Math.random() * 3); // 0, 1, or 2
          const providerStatusOptions = ["Active", "Deactivated", "Archived"];
          const providerStatus = providerStatusOptions[randomStatusIndex];
          return {
            displayName: `${actor?.user?.firstName} ${actor?.user?.lastName}`,
            email: actor?.user?.email,
            providerStatus,
            ...actor,
          };
        }
      );
    });

    builder.addCase(getDomainConfig.rejected, (state, action) => {
      state.loading = false;
    });
  },
  reducers: {},
});

// Export reducer
export default domainConfigSlice.reducer;
