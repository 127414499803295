import "reflect-metadata";

import { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { paths } from "./routePaths";
import PrivateRoute from "./PrivateRoute";
import ScrollToTop from "./ScrollToTop";
import VerifyLayout from "pages/VerifyLayout";
import AuthPermissionRoute from "./AuthPermissionRoute";
import AnalyticsHome from "pages/AnalyticsHome";
const CustomTokenAuthWithPermission = lazy(
  () => import("components/Auth/CustomTokenAuthWithPermission")
);

const AnonymousAuth = lazy(() => import("components/Auth/AnonymousAuth"));
const NotAuthorized = lazy(() => import("components/Auth/NotAuthorized"));
const MainLayout = lazy(() => import("pages/MainLayout"));
const NotFound = lazy(() => import("components/Auth/NotFound"));

const Provisioned = lazy(() => import("pages/Provisioned"));
const Secret = lazy(() => import("pages/Secret"));
const Two = lazy(() => import("pages/Two"));
const UserManagment = lazy(() => import("pages/UserManagment"));
const Verify = lazy(() => import("pages/Verify"));
const SigninRedirect = lazy(() => import("components/Auth/SigninRedirect"));

export default function AppRoutes() {
  //DomainConfig();
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route element={<MainLayout />}>
            {/* Unprotected routes */}

            <Route path={paths.helpCenter} element={<Two />} />

            <Route
              path={paths.secretPage}
              element={
                <PrivateRoute>
                  <Secret />
                </PrivateRoute>
              }
            />
            <Route
              path={paths.noAccess}
              element={
                <PrivateRoute>
                  <Provisioned />
                </PrivateRoute>
              }
            />
            <Route
              path={paths.index}
              element={
                <PrivateRoute>
                  <AuthPermissionRoute allowedRole="analytics">
                    <AnalyticsHome />
                  </AuthPermissionRoute>
                </PrivateRoute>
              }
            ></Route>
            <Route
              path={paths.userManagment}
              element={
                <PrivateRoute>
                  <AuthPermissionRoute allowedRole="user_mgmt">
                    <UserManagment />
                  </AuthPermissionRoute>
                </PrivateRoute>
              }
            ></Route>
            {/* SSO Redirect Page */}
            <Route path="sign-in" element={<SigninRedirect />} />

            {/* Custom token sing in page */}
            <Route path="anonymous" element={<AnonymousAuth />} />

            {/* Custom token sing in page */}
            <Route path="auth" element={<CustomTokenAuthWithPermission />} />

            {/* Error Pages */}
            <Route path="401" element={<NotAuthorized />} />
            <Route path="*" element={<NotFound />} />
          </Route>

          <Route element={<VerifyLayout />}>
            <Route path={paths.verify} element={<Verify />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}
