import LoadingSpinner from "components/LoadingSpinner";
import useAuth from "hooks/useAuth";
import { useEffect } from "react";
import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
  useNavigate,
} from "react-router-dom";

interface PrivateRouteProps {
  children: React.ReactElement;
  redirectTo?: string;
}

const DEFAULT_REDIRECT_PATH = "/sign-in";

const PrivateRoute = ({
  children,
  redirectTo = DEFAULT_REDIRECT_PATH,
}: PrivateRouteProps) => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!currentUser || !currentUser.uid) {
        // Redirect to the specified route if the user is not authenticated
        /*  Navigate({
          to: redirectTo,
          state: { from: `${location.pathname}?${searchParams.toString()}` },
        }); */
        navigate(redirectTo, {
          state: { from: `${location.pathname}?${searchParams.toString()}` },
        });
      }
    }, 1000);

    return () => clearTimeout(timeoutId); // Clean up the timeout when the component unmounts
  }, [currentUser, location.pathname, redirectTo, searchParams, navigate]);

  return currentUser && currentUser.uid ? children : <LoadingSpinner />;
};

export default PrivateRoute;

export function PrivateOutlet({
  redirectTo = DEFAULT_REDIRECT_PATH,
}: {
  redirectTo?: string;
}) {
  const { currentUser } = useAuth();
  const location = useLocation();
  return currentUser?.uid ? (
    <Outlet />
  ) : (
    <Navigate to={redirectTo} state={{ from: location.pathname }} />
  );
}
