import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import domainConfigSlice from "./features/domainConfigSlice";
import usermanagementSlice from "./features/userManagementSlice";
import userActorSlice from "./features/userActorsDetails";

// Combine reducers
const reducer = combineReducers({
  domain: domainConfigSlice,
  userManagement: usermanagementSlice,
  userActors: userActorSlice,
});

//persistant setting
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

// Create Store equivalent
export const store = configureStore({
  reducer: persistedReducer,
  // We don't use thunk
  //middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(),]
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
