import { Expose, Type } from "class-transformer";
import { Network } from "./subObjects/Network";
import { NetworkActorUser } from "./subObjects/NetworkActorUser";

export class NetworkUser {
  constructor(obj: object = {}) {
    Object.assign(this, obj);
  }

  @Expose({ name: "network_actor_id" })
  public network_actor_id?: string;

  @Expose({ name: "network_id" })
  public network_id?: boolean;

  @Expose({ name: "active" })
  public active?: string;

  @Expose({ name: "user_id" })
  public user_id?: string;

  @Expose({ name: "actor_role" })
  public actor_role?: string;

  @Expose({ name: "created_date" })
  public created_date?: string;

  @Expose({ name: "created_by_user_id" })
  public created_by_user_id?: string;

  @Expose({ name: "updated_date" })
  public updated_date?: string;

  @Expose({ name: "updated_by_user_id" })
  public updated_by_user_id?: string;

  @Expose({ name: "analytics" })
  public analytics?: boolean;

  @Expose({ name: "enrollments" })
  public enrollments?: boolean;

  @Expose({ name: "user_mgmt" })
  public user_mgmt?: boolean;

  @Type(() => Network)
  @Expose({ name: "network" })
  public network?: Network = new Network();

  @Type(() => NetworkActorUser)
  @Expose({ name: "user" })
  public networkActors?: NetworkActorUser = new NetworkActorUser();
}
