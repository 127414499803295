import React, { useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/solid";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
interface Option {
  id: string;
  name: string;
}
interface ComboBoxProps {
  options: Option[];
  label?: string;
  onSelect: (option: Option | null) => void;
  value?: Option;
  testIdPrefix?: string; // Add a prop for the testIdPrefix
}

const ComboBox: React.FC<ComboBoxProps> = ({
  options,
  label,
  onSelect,
  value,
  testIdPrefix = "combobox", // Use "combobox" as the default prefix
}) => {
  const [selected, setSelected] = useState<Option | null>(value || null);

  const handleOptionSelect = (option: Option) => {
    setSelected(option);
    onSelect(option);
  };
  useEffect(() => {
    setSelected(value || null);
  }, [value]);
  return (
    <div className="relative mt-2 ">
      <Listbox value={selected} onChange={handleOptionSelect}>
        {({ open }) => (
          <>
            <Listbox.Button
              className="relative w-full cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-tail-blue shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              data-testid={`${testIdPrefix}-listbox-button`}
            >
              <span
                className="block truncate"
                data-testid={`${testIdPrefix}-selected-option-display`}
              >
                {selected ? selected.name : "Select"}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-tail-blue"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Listbox.Options
                static
                className="absolute z-10 mt-1 max-h-60 w-full py-1  text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm rounded-md bg-white"
                data-testid={`${testIdPrefix}-listbox-options`}
              >
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    value={option}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-indigo-600 text-white" : "text-tail-blue",
                        "relative cursor-pointer select-none py-2 pl-3 pr-9"
                      )
                    }
                    data-testid={`${testIdPrefix}-listbox-option-${option.id}`}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {option.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            {active && (
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default ComboBox;
