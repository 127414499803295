import { plainToInstance } from "class-transformer";
import http from "./httpService/http";
import { paths } from "./paths";
import { AnalyticReport } from "./dataObjects/AnalyticReport";

const reportListPath = paths.network_dashboard_report.report;

export const fetchAnalyticReportList = async (
  network_id: string
): Promise<AnalyticReport> => {
  const response = await http.get<object, any>(reportListPath(network_id));
  return plainToInstance(AnalyticReport, response.data) as AnalyticReport;
};
