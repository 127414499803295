import LoadingSpinner from "components/LoadingSpinner";
import { useEffect, useState } from "react";

interface SisenseComponentProps {
  url?: string;
  wat?: string;
  dashboard?: string;
}
const SisenseComponent: React.FC<SisenseComponentProps> = ({
  url = "",
  wat = "",
  dashboard = "",
}) => {
  const [showLoader, setShowLoader] = useState<boolean>(true);
  /* const {
    url = "https://wonderschool.sisense.com",
    wat = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSU0EtT0FFUC0yNTYiLCJ6aXAiOiJERUYiLCJlbmMiOiJBMTI4R0NNIiwia2lkIjoiNjRmOGQyZTQyOTFjNjYwMDMyNjU2ZGRhIn0.mwIPPZdsYcrrfjaRutoXbcG-4u70ARoypKP7JayYDrcmmt0MNzZ2goGcZS8wZmZuCaGHU5-HktLfTJTvb19bd3KKwhFMCorQRMFkskIOGwbP6KXqDvEsU0SLS172FHtWVKj2LLzMItWOxl6WtNi5T9e7JOJthw-WxRrUzRPq_PuGXrABBGy1_TvK7RuonIzUHFb7i9IdnoxtvkrRIJ6Y1Nau5Hgq1upSiDsR95D7xNeOcJ6bVliIsKtKheO_X2Db5QezaQQmfCVH65sgKG_7qgXM0XaL6EbC7SmZPhnY-qI9YuKiUCeISOqjqs_7nBPFeSD7Tzuj_kYpur6UTSRRyQ.EnQq2zO7c-qtc3Ot.R2TcJ_3FaEj5eBNE0aaaF3BVAw0fqcgR-4MFgP7kTHMUpI6wXmIksGdD_sovFsGR6aviDu0ONwWd5g1_7vIPw85xq8E7baa7CbNkZs6ZinMmoqtYylYgYh2iUnrtTm0ChpRQHGOQqhJjMDiGEp5DUy1-QHM3Na6k1KvGoYP34TXH1rlchJjQhI78-aOAp9br6RogbX5U8pS05OeUjTicrVw797ia.42I7vUNxYU8U0AfPHvXueg",
    dashboard = "6526c4a78067f40033260cb4",
  } = props; */
  function dashboardLoadedHandler(args: any) {
    console.log("Dashboard " + args.dashboard.oid + " loaded!");
  }
  useEffect(() => {
    setTimeout(() => setShowLoader(false), 20000);
    try {
      setShowLoader(true);
      const embed = (window as any)["sisense.embed"];

      const SisenseFrame = embed.SisenseFrame;
      const enums = embed.enums;

      const sisenseFrame = new SisenseFrame({
        // Sisense application URL, including protocol and port if required
        url,
        // Web access token:
        wat,
        // OID of dashboard to load initially
        dashboard,
        // Which panels to show in the iFrame
        settings: {
          showLeftPane: false,
          showToolbar: false,
          showRightPane: true,
        },
        // You can define existing <iframe/> DOM element here or
        // pass some <div/> as a parameter to render function below
        // and <iframe/> will be rendered inside that <div/> dynamically
        element: document.getElementById("sisense-frame"),
        //id: "sisense-frame",
      });

      sisenseFrame.render().then(() => {
        setShowLoader(false);
        console.log("Sisense iFrame ready!", sisenseFrame._state.dashboard);
      });

      // Define an event handler

      // Subscribe to the dashboard loaded event
      sisenseFrame.dashboard.on(
        enums.ApplicationEventType.LOADED,
        dashboardLoadedHandler
      );

      // Unsubscribe the handler from the event
      sisenseFrame.dashboard.off(
        enums.ApplicationEventType.LOADED,
        dashboardLoadedHandler
      );
    } catch (e) {
      setShowLoader(false);
      console.log("Sisense frame SDK embedding Error:", e);
    }
  }, [url, wat, dashboard]);

  return (
    <div>
      <div className={`${showLoader ? "block" : "hidden"}`}>
        <LoadingSpinner />
      </div>
      {wat === "" || dashboard === "" ? (
        <div> Dashboard not available</div>
      ) : (
        <div className={`${!showLoader ? "block" : "hidden"}`}>
          <iframe
            id="sisense-frame"
            title="sisense-sdk"
            style={{ height: "100vh" }}
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default SisenseComponent;
