import { NetworkUser } from "api/dataObjects/NetworkUser";
import { fetchUserDetails } from "api/networkUserSvc";
import LoadingSpinner from "components/LoadingSpinner";
import { useAppDispatch, useAppSelector } from "hooks/reduxHook";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import { useLocation, Navigate, redirect } from "react-router-dom";
import { getUserActor } from "reduxApp/features/userActorsDetails";
import { paths } from "./routePaths";

interface Roles {
  children: React.ReactNode;
  allowedRole: string;
}

const AuthPermissionRoute: React.FC<Roles> = ({ children, allowedRole }) => {
  const [isApiCallComplete, setApiCallComplete] = useState<boolean>(true);
  const [permissionIs, setPermissionIs] = useState<boolean>(true);
  const { user } = useAuth();
  const domainReduxDataNetworkId: string = useAppSelector(
    (state) => state?.userActors?.actors[0]?.network_id
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    (async () => {
      try {
        setApiCallComplete(false);
        await dispatch(getUserActor());
        if (user?.uid !== undefined && domainReduxDataNetworkId) {
          const userData: NetworkUser = await fetchUserDetails(
            user?.uid || "",
            domainReduxDataNetworkId
          );

          // Access the property based on the allowedRole
          if (allowedRole === "analytics") {
            setPermissionIs(!!userData.analytics);
          } else if (allowedRole === "user_mgmt") {
            setPermissionIs(!!userData.user_mgmt);
          }
          setApiCallComplete(true);
        } else {
          redirect(paths.noAccess);
        }
      } catch (e) {
        console.error("Error fetching user data:", e);
        setApiCallComplete(true);
      } finally {
        //setApiCallComplete(true); // Ensure the flag is set whether the call succeeds or fails
      }
    })();
  }, [allowedRole, user?.uid, domainReduxDataNetworkId, dispatch]);

  const location = useLocation();

  // Check if the API call is complete before rendering
  if (!isApiCallComplete) {
    return <LoadingSpinner />; // You can render a loading indicator here if needed
  }

  return permissionIs ? (
    <>{children}</>
  ) : (
    <Navigate to={paths.noAccess} state={{ from: location }} replace />
  );
};

export default AuthPermissionRoute;
