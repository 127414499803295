import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { UserMgmtRecord } from "api/dataObjects/UserManagment";
import { fetchUserManagementList } from "api/networkUserSvc";

export type UserMgmtRecordForUI = UserMgmtRecord & {
  displayName: string;
};

type IinitialState = {
  users: UserMgmtRecord;
  loading: boolean;
  networkActorsList: UserMgmtRecordForUI[];
};

const initialState: IinitialState = {
  users: [] as UserMgmtRecord,
  loading: false,
  networkActorsList: [] as UserMgmtRecordForUI[],
};

// function actually call get for domain config data
export const getUserData = createAsyncThunk(
  "get/fetchuserManagement",
  async (network_id: string, { rejectWithValue }) => {
    try {
      const response = await fetchUserManagementList(network_id);
      return JSON.stringify(response);
    } catch (err: any) {
      return rejectWithValue({ error: err.message });
    }
  }
);

const userManagementSlice = createSlice({
  name: "userManagement",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(getUserData.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getUserData.fulfilled, (state, action) => {
      const userManagementData = JSON.parse(action.payload);
      state.users = userManagementData;
      state.loading = false;
      state.networkActorsList = userManagementData.map(
        (actor: UserMgmtRecord) => {
          return {
            displayName: `${actor?.first_name} ${actor?.last_name}`,
            ...actor,
          };
        }
      );
    });

    builder.addCase(getUserData.rejected, (state, action) => {
      state.loading = false;
    });
  },
  reducers: {},
});

// Export reducer
export default userManagementSlice.reducer;
