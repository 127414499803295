//
// This file has all the paths to the endpoints
// All endpoints should have the same path for all methods
//  ^Create can pass empty string for id, cause id is omitted for post
//

export const paths = {
  anonymous: {
    domain: () => `/anonymous/api/domain/${window.location.hostname}`, //${window.location.hostname}
    inviteAcceptGet: (network_invite_id: string, secret: string) =>
      `/anonymous/api/accept_invite/${network_invite_id}/${secret}`,
    inviteAcceptPost: () => `/anonymous/api/accept_invite`,
  },
  network_users: {
    userList: (network_id: string) =>
      `/api/network/${network_id}/user_management`,
  },
  invite: {
    /**
     * This function will return the API path for creating and sending a
     * NetworkInvite.
     *
     * @param network_id The id of the Network to which the User we are
     * inviting belongs.
     * @returns The path to the endpoint.
     */
    createNetworkInvitePath: (network_id: string) =>
      `/api/network/${network_id}/invite`,

    /**
     * This function will return the API path for reading, updating, and/or
     * resending a NetworkInvite, as follows:
     *
     * `GET`: For reading, the network_invite_id should be provided
     * and the body should be empty.
     *
     * `PUT`: For updating+resending, the network_invite_id should be
     * provided and the body should contain the updated values.
     *
     * `PATCH`: For resending without updating, the network_invite_id
     * should be provided and the body should be empty.
     *
     * @param network_id The id of the Network to which the User for whom we
     * are reading or updating the NetworkInvite belongs.
     * @param network_invite_id The id of the NetworkInvite for which we are
     * reading or updating.
     * @returns The path to the endpoint.
     */
    networkInviteByIdPath: (network_id: string, network_invite_id: string) =>
      `/api/network/${network_id}/invite/${network_invite_id}`,
  },
  actor: {
    userGet: (network_id: string, network_invite_id: string) =>
      `/api/network/${network_id}/actor/${network_invite_id}`,
  },
  user: {
    userGet: (user_id: string, network_id: string) =>
      `/api/user/${user_id}/network/${network_id}/actor`,
    getLoginUser: () => `/api/user/actors`,
  },
  network_dashboard_report: {
    report: (network_id: string) => `/api/network/${network_id}/analytics`,
  },
};
