import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchLoginUserDetails } from "api/networkUserSvc";
import { IUserActor } from "../types";

type IinitialState = {
  actors: IUserActor[];
  loading: boolean;
};

const initialState: IinitialState = {
  actors: [] as IUserActor[],
  loading: false,
};

// function actually call get for domain config data
export const getUserActor = createAsyncThunk(
  "get/fetchLoginUserDetails",
  async (dummy, { rejectWithValue }) => {
    try {
      const response = await fetchLoginUserDetails();
      return JSON.stringify(response);
    } catch (err: any) {
      return rejectWithValue({ error: err.message });
    }
  }
);

const userActorSlice = createSlice({
  name: "userActor",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(getUserActor.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getUserActor.fulfilled, (state, action) => {
      const userActorsData = JSON.parse(action.payload);
      state.actors = userActorsData;
      state.loading = false;
    });

    builder.addCase(getUserActor.rejected, (state, action) => {
      state.loading = false;
    });
  },
  reducers: {},
});

// Export reducer
export default userActorSlice.reducer;
