import PageBaseWithoutPanel from "components/PageLayout/PageBaseWithoutPanel";
//import { Outlet, useLocation } from 'react-router-dom';
import { Outlet } from "react-router-dom";
//import { AppRouteKey, APP_ROUTES } from 'routes/types';

export default function VerifyLayout() {
  //  const { pathname } = useLocation();
  //  const pageProps = APP_ROUTES[pathname as AppRouteKey];
  const pageProps = {
    pageTitle: "Network Portal",
    appName: "Wonderschool",
  };
  return (
    <PageBaseWithoutPanel {...pageProps}>
      <Outlet />
    </PageBaseWithoutPanel>
  );
}
