import React from "react";
import clsx from "clsx";
interface ILoadingSpinnerProps {
  isScreenFull?: boolean;
  size?: "BIG" | "SMALL";
}
const LoadingSpinner: React.FC<ILoadingSpinnerProps> = ({
  isScreenFull = true,
  size = "BIG",
}) => {
  return (
    <div
      className={clsx(
        "flex items-center justify-center",
        isScreenFull ? "h-screen" : "w-full"
      )}
    >
      <div
        className={`border-t-4 border-r-4 border-b-4 border-l-4 border-b-white border-l-white border-tail-blue border-solid ${
          size === "SMALL" ? "h-16 w-16" : "h-28 w-28"
        }  rounded-full animate-spin`}
      ></div>
    </div>
  );
};

export default LoadingSpinner;
