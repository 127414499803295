interface SisenseListBoxProps {
  report: {
    analytic_report_id?: string;
    network_id?: string;
    active?: boolean;
    display_order?: number;
    report_name?: string;
    preview_image_url?: string;
    sisense_web_access_token?: string;
    sisense_url?: string;
    sisense_dashboard_id?: string;
  };
  callback: Function;
  dataTestId?: string;
}

const SisenseListBox: React.FC<SisenseListBoxProps> = ({
  report,
  callback,
  dataTestId = "sisence-card",
}) => {
  return (
    <>
      <div
        className="relative rounded-lg  cursor-pointer"
        onClick={() =>
          callback({
            id: report.sisense_dashboard_id,
            name: report.report_name,
          })
        }
        data-testid={dataTestId}
      >
        {/* Background Image */}
        <img
          src={report.preview_image_url}
          alt="image_name"
          className="w-80 h-52"
        />
        {/* Gray Overlay */}
        <div className="absolute inset-0 bg-blue-950 rounded-lg border border-zinc-300 opacity-70"></div>

        {/* Text Container */}
        <div className="absolute inset-0 flex flex-col justify-center items-center text-white">
          <div className="text-white text-sm font-bold font-['Moderat'] leading-tight">
            {report.report_name}
          </div>
        </div>
      </div>
    </>
  );
};

export default SisenseListBox;
