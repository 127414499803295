import { plainToInstance } from "class-transformer";
import { DomainConfig } from "./dataObjects/DomainConfig";
import http from "./httpService/http";
import { paths } from "./paths";

const domainPath = paths.anonymous.domain;

export const fetchDomainConfig = async (): Promise<DomainConfig> => {
  const response = await http.get<object, any>(domainPath());
  return plainToInstance(DomainConfig, response.data) as DomainConfig;
};
