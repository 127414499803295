export class UserMgmtRecord {
  constructor(obj: object = {}) {
    Object.assign(this, obj);
  }
  // Both
  public network_id?: string;

  // Actor Only
  public network_actor_id?: string | null = null;

  // Actor Only - Inactive invites not shown
  public active?: boolean | null = null;

  // Invite Only
  public network_invite_id?: string | null = null;

  // Invite Only
  public status?: string | null = null;

  // Both, Null for invited but not accepted
  public user_id?: string | null = null;

  // Both - pulled from User Object for Actor
  public email?: string;

  // Both - pulled from User Object for Actor
  public first_name?: string;

  // Both - pulled from User Object for Actor
  public last_name?: string;

  // Both
  public actor_role?: string;

  // Both
  public created_date?: Date;

  // Both
  public created_by_user_id?: string;

  // Both
  public updated_date?: Date;

  // Both
  public updated_by_user_id?: string;

  // Invite Only
  public claimed?: boolean | null = null;

  // Invite Only
  public claimed_date?: Date | null = null;

  //
  // Product enablement flags
  //
  // Both
  public analytics?: boolean = false;

  // Both
  public enrollments?: boolean = false;

  // Both
  public user_mgmt?: boolean = false;
}
