import { createContext, Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import GlobalFontModerat from "theme/chakra/baseFont";
import AuthProvider from "./AuthProvider";
import LanguageProvider from "./LanguageProvider";
import LoadingSpinner from "components/LoadingSpinner";

const ApplicationContext = createContext(null);

interface ApplicationProviderProps {
  children: React.ReactNode;
}

// This is a high level provider that wraps the entire application.
// Useful to wrap App with application-level providers.
// If there's a need to define application level state, it can be set as ApplicationContext.Provider.value.
export default function ApplicationProvider({
  children,
}: ApplicationProviderProps) {
  return (
    <ApplicationContext.Provider value={null}>
      {/* Wrap everything with suspense provider, i18n errors out if there's no top level Suspense */}
      <Suspense fallback={<LoadingSpinner />}>
        {/* Authentication Provider */}
        <AuthProvider>
          {/* Language Provider */}
          <LanguageProvider>
            {/* Helmet Provider */}
            <HelmetProvider>
              {/* Chakra Wrapper with Provider */}
              <GlobalFontModerat />
              {children}
            </HelmetProvider>
          </LanguageProvider>
        </AuthProvider>
      </Suspense>
    </ApplicationContext.Provider>
  );
}
