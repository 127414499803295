import useAppLanguage from 'hooks/useAppLanguage';
import { Helmet } from 'react-helmet-async';

interface HelmetTitleProps {
  pageTitle: string;
  appName?: string;
  link?: HTMLLinkElement;
}

export default function HelmetTitle({
  pageTitle,
  appName,
  link,
}: HelmetTitleProps) {
  const { translate } = useAppLanguage();

  return (
    <Helmet>
      <title>
        {pageTitle.length ? `${translate(pageTitle)} - ` : ''}
        {appName?.length ? translate(appName) : ''}
      </title>
      {link && (
        <link
          rel={link.rel}
          href={link.href}
          key={`${link.rel}-${link.href}`}
        />
      )}
    </Helmet>
  );
}
